import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typist from "react-typist";
import Modal from "@material-ui/core/Modal";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Style from "./style.js";
import Logo from "../../../../assets/images/CashmereCompanyLogo.png";
import ImgDonna from "../../../../assets/images/donna.png";
import ImgUomo from "../../../../assets/images/uomo.png";
import ImgMadeInItaly from "../../../../assets/images/made-in-italy.webp";
import ImgArticoliInSaldo from "../../../../assets/images/articoli-in-saldo.png";


const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
      opacity: 0.8,
    },
  },
}))(Button);

const Layout = ({
  dataGenders,
  dataSeason,
  onChangeSeason,
  modalFilterShop,
  filterShop,
  openModal,
  closeModal,
  onGo,
  dataShowBanner,
  dataSaleProducts,
  dataBestsellerProducts,
  dataLuxuryProducts,
  history,
  dataMe
}) => {
  const womanId = dataGenders.genders.find(item => item.slug === "donna") || false;
  const manId = dataGenders.genders.find(item => item.slug === "uomo") || false;
  const style = Style();

  return (
    <>
      <div style={{ marginBottom: "1rem", marginTop: "1rem", maxWidth: "1240px", margin: "auto" }}>
        <img src={Logo} alt="logo" width="300px" style={{ margin: "60px auto 20px", display: "block" }} />

        {/* PREORDER */}
        {(dataSeason.seasons ?? []).filter(season => season.is_preorder === 1 && season.not_visible !== 1).length > 0 && dataMe.me.agent?.id && dataMe.me.agent?.preorder &&
          <Grid item xs={12} style={{ margin: "90px 0 0" }}>
            <div onClick={() => history.push("/preorder")} style={{ width: "max-content", margin: "auto", cursor: "pointer" }}>
              <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>PREORDER</b></Typography>
              <Divider className={style.categoryDivider} />
              <Typography color="primary" align="center" style={{ marginTop: "4px", fontSize: "20px" }}>VAI</Typography>
            </div>
          </Grid>
        }
        <Grid container spacing={3} style={{ marginTop: "30px" }}>
          <Grid item xs={12} md={6}>
            <img
              src={ImgUomo}
              alt="immagine-uomo"
              style={{ margin: "auto", display: "block", minWidth: "320px", marginBottom: "-10px", width: "35%", cursor: "pointer" }}
              onClick={manId ? () => openModal(manId.id) : () => openModal("no-gender")} />
            <div onClick={manId ? () => openModal(manId.id) : () => openModal("no-gender")} style={{ width: "max-content", margin: "auto", cursor: "pointer" }}>
              <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>UOMO</b></Typography>
              <Divider className={style.categoryDivider} />
              <Typography color="primary" align="center" style={{ marginTop: "4px", fontSize: "20px" }}>VAI</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={ImgDonna}
              alt="immagine-donna"
              style={{ margin: "auto", display: "block", minWidth: "320px", marginBottom: "-10px", width: "35%", cursor: "pointer" }}
              onClick={womanId ? () => openModal(womanId.id) : () => openModal("no-gender")} />
            <div onClick={womanId ? () => openModal(womanId.id) : () => openModal("no-gender")} style={{ width: "max-content", margin: "auto", cursor: "pointer" }}>
              <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>DONNA</b></Typography>
              <Divider className={style.categoryDivider} />
              <Typography color="primary" align="center" style={{ marginTop: "4px", fontSize: "20px" }}>VAI</Typography>
            </div>
          </Grid>
          {dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'sale')].show === 1 && dataSaleProducts?.getSaleProducts.length > 0 && (
            <Grid item xs={12} md={6}>
              <img
                src={ImgArticoliInSaldo}
                alt="immagine-articoli-in-saldo"
                width="50%"
                style={{ margin: "auto", display: "block", minWidth: "320px", marginBottom: "-10px", width: "35%", cursor: "pointer" }}
                onClick={() => history.push("/sale")} />
              <div onClick={() => history.push("/sale")} style={{ width: "max-content", margin: "auto", cursor: "pointer" }}>
                <Typography fontSize="12px" color="primary" align="center"><b>ARTICOLI IN</b></Typography>
                <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>SALDO</b></Typography>
                <Divider className={style.categoryDivider} />
                <Typography color="primary" align="center" style={{ marginTop: "4px", fontSize: "20px" }}>VAI</Typography>
              </div>
            </Grid>
          )}
          {dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'bestseller')].show === 1 && dataBestsellerProducts.getBestsellerProducts.length > 0 && (
            <Grid item xs={12} md={6}>
              <img
                src={ImgMadeInItaly}
                alt="immagine-made-in-italy"
                style={{ margin: "auto", display: "block", minWidth: "320px", marginBottom: "-10px", width: "35%", cursor: "pointer" }}
                onClick={() => history.push("/made-in-italy")} />
              <div onClick={() => history.push("/made-in-italy")} style={{ width: "max-content", margin: "auto", cursor: "pointer" }}>
                <Typography fontSize="12px" color="primary" align="center"><b>MADE IN</b></Typography>
                <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>ITALY</b></Typography>
                <Divider className={style.categoryDivider} />
                <Typography color="primary" align="center" style={{ marginTop: "4px", fontSize: "20px" }}>VAI</Typography>
              </div>
            </Grid>
          )}
          {/* PREORDER */}
          {dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 &&
            <Grid item xs={12} style={{ margin: "90px 0 30px" }}>
              <div onClick={() => history.push("/luxury")} style={{ width: "max-content", margin: "auto", cursor: "pointer" }}>
                <Typography fontSize="12px" color="primary" align="center"><b>SEMPRE DISPONIBILI</b></Typography>
                <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>NOOS</b></Typography>
                <Divider className={style.categoryDivider} />
                <Typography color="primary" align="center" style={{ marginTop: "4px", fontSize: "20px" }}>VAI</Typography>
              </div>
            </Grid>
          }
        </Grid>
        <Typography variant="h3" align="center" color="primary" style={{ letterSpacing: "8px", margin: "60px 0 8px" }}><b>FALL WINTER {new Date().getFullYear()}</b></Typography>
        <Typography fontSize="8px" align="center" style={{ marginBottom: "4px", color: "#88695f" }}><b>L’ELEGANZA DEL CASHMERE TRA TRADIZIONE E INNOVAZIONE</b></Typography>
        <Typography fontSize="8px" align="center" style={{ marginBottom: "30px", color: "#88695f" }}><b>THE ELEGANCE OF CASHMERE AMONG TRADITION AND MODERNISATION</b></Typography>
        <div className={style.textWrapper}>
          <Typography><b>Cashmere Company</b> lavora fibre preziose che rappresentano il cuore di ogni suo capo. Sin dalla sua fondazione, la mission è stata quella di creare indumenti che combinassero la qualità straordinaria del cashmere con un design che riuscisse a fondere lo stile classico con le tendenze contemporanee, offrendo <b>capi che siano allo stesso tempo moderni e senza tempo</b>.</Typography>
          <Typography><b>Cashmere Company</b> processes precious fibres that embody the core of each of its garments. Since its foundation, the mission has been to create pieces of clothing that combine the extraordinary quality of cashmere with a design that blends classic style with contemporary trends, <b>offering attires that are both modern and timeless</b>.</Typography>
        </div>

      </div>
      <Modal
        open={modalFilterShop}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={style.modal}
      >
        <div className={style.modalBody}>
          <div className={style.modalTitle}>
            <Typography component="h1" variant="h5" align="center">Seleziona una stagione</Typography>
            <span className={style.modalClose} onClick={closeModal}>x</span>
          </div>
          <div className={style.modalContent}>
            <FormControl variant="outlined" size="small" className={style.select}>
              <InputLabel id="demo-simple-select-outlined-label">Stagione</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filterShop.season}
                onChange={onChangeSeason}
                label="Stagione"
                disabled={false}
                fullWidth
              >
                <MenuItem value="all">Tutte le stagioni</MenuItem>
                {
                  dataSeason && dataSeason.seasons !== null && dataSeason.seasons.length > 0 ? (
                    dataSeason.seasons.filter(item => !item.is_preorder).map((item, index) => {
                      if (!item.not_visible) {
                        return (<MenuItem key={index + "sizes"} value={item.id}>{item.name}</MenuItem>)
                      }
                    })
                  ) : (
                    <MenuItem value="">non ci sono stagioni</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </div>
          <div className={style.modalFooter}>
            <ColorButton
              style={{ backgroundColor: "#472920", color: "#ffffff" }}
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              onClick={onGo}
            >
              Vai
            </ColorButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Layout;
